<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="角色名称">
				  <c-input name="role_name"></c-input>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_role_edit')" color="sys" @click="navigateTo('/role_add')">新建角色</c-button>
			</m-operate>
			
			<c-table ref="table" height="grow">
				<c-table-column
					label="角色名称"
					name="role_name"
					width="110"
				>
				</c-table-column>
				
				<c-table-column
					label="描述"
					name="remarks"
					width="200"
				>
				</c-table-column>
				
				<c-table-column
					label="创建时间"
					name="create_date"
					width="140"
				>
				</c-table-column>
				
				<c-table-column
					type="button"
					fixed="right"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_role_edit')" @click="navigateTo(`/role_edit/${data.id}`)">编辑</c-table-button>
						<c-table-button v-if="auth('b_role_edit')" @click="deleteHandle(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
	export default {
		name: 'm_role',
		
		data() {
			return {
				powerList: []
			}
		},
		
		computed: {
			powerAllCheck() {
				return !!(this.powerList.length && !this.powerList.find(item => item.checked == 0));
			}
		},
		
		mounted() {
			this.$refs.search.search();
			this.$bus.$on('roleList.update', page => {
				this.$refs.table.update(page);
			});
		},
		
		methods: {
			searchHandle(data) {
				this.$refs.table.load({
					url: 'sys_role/list',
					data
				});
			},

			deleteHandle(data) {
				this.$confirm({
					message: '确定要删除【'+data.role_name+'】吗？',
					resolve: () => {
						this.request({
							url: 'sys_role/delete',
							data: {
								role_id: data.id,
							},
							loading: true,
							success: data => {
								this.$message({
									type: 'success',
									message: '操作成功'
								});
								this.$refs.table.update();
							}
						});
					}
				});
			},

			fieldPower(data) {
				this.request({
					url: 'sys_role/field_list',
					data: {
						role_id: data.id,
					},
					success: data => {
						this.powerList = data
					}
				});
				this._edit = data.id;
				this.powerList = [];
				this.$refs.fieldPower.open();
			},
			
			checkPower(item, state) {
				if (item == 'all') {
					this.powerList.forEach(item => {
						this.checkPower(item, state);
					});
				} else {
					item.checked = state ? 1 : 0;
				}
			},

			submitFieldPower() {
				this.request({
					url: 'sys_role/field_edit',
					data: {
						role_id: this._edit,
						field_ids: Array.from(this.powerList.filter(item => item.checked != 0), item => {
							return {
								field_id: item.id
							}
						})
					},
					loading: true,
					success: data => {
						this.$message({
							type: 'success',
							message: '保存成功'
						});
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.power {
		height: 300px;
		overflow: auto;
		
		&-list {
			clear: both;
			overflow: hidden;
			
			.power-list {
				margin-left: 40px;
				margin-bottom: 20px;
			}
		}
		
		&-item {
			float: left;
			margin-top: 15px;
			margin-left: 20px;
		}
	}
</style>